import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  * {
    box-sizing: border-box;
  }


  body {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  a:hover {
    color: #000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1A1917;
  }

  /* pictures and images must be defined as display: block;
    Otherwise they are considered to be fonts and show font descenders
    below the baseline.
    https: //stackoverflow.com/questions/31444891/  mystery-white-space-underneath-image-tag/31445364#31445364
    */

  img {
    display: block;
    font-size: 0;
    line-height: 0;
    width: 100%;
    margin: 0;
  }

  picture {
    background: transparent;
    display: block;
    font-size: 0;
    line-height: 0;
    width: 100%;
    overflow: hidden;
    margin: 0;
  }

  .blur-up .lazyload,
  .blur-up .lazyloaded {
    filter: blur(5px);
    transition: filter 400ms;
    image-rendering: crisp-edges;
  }

  .lazyloaded {
    filter: blur(0px);
    image-rendering: pixelated;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  .caption {
    margin-top: -24px;
    margin-bottom: 24px;
    text-align: right;
  }

`

export default GlobalStyle
