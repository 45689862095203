import lazySizes from "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "fontsource-istok-web"
import "fontsource-crimson-text"

import CookieConsent from "react-cookie-consent"
import GlobalStyle from "./styled/GlobalStyle"
import { ThemeProvider } from "styled-components"
import { Flex } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "@fortawesome/fontawesome-svg-core/styles.css"

import { library } from "@fortawesome/fontawesome-svg-core"
// import {
//   fab,
//   faFacebookSquare,
//   faTwitterSquare,
// } from "@fortawesome/free-brands-svg-icons"
import {
  faBook,
  faPencil,
  faQuestion,
  faShoppingCart,
  faMindShare,
} from "@fortawesome/pro-light-svg-icons"

import { faCcPaypal, faInstagram } from "@fortawesome/free-brands-svg-icons"

import theme from "../../theme"
import Container from "../Container"
import Header from "../Header"
import HeaderAddress from "../HeaderAddress"
import Logo from "../Logo"
import Main from "../Main"
import Footer from "../Footer"
import MenuSection from "../MenuSection"
import MenuBar from "../MenuBar"
import Menu from "../Menu"
import MenuItem from "../MenuItem"
import NoWrap from "../NoWrap"

import logoVA from "../../img/logoVA.svg"

// loads all images directly after page is loaded
// lazySizes.cfg.preloadAfterLoad = true

library.add(
  faBook,
  faPencil,
  faQuestion,
  faMindShare,
  faShoppingCart,
  faCcPaypal,
  faInstagram
)

let browser = {}
// Wrap the require in check for window
if (typeof window !== `undefined`) {
  const bowser = require("bowser")
  browser = bowser.getParser(window.navigator.userAgent).getBrowser()
} else {
  browser.name = "undefined"
  browser.version = ""
}

const Layout = ({ children, props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            date
          }
        }
      }
    `
  )
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyle />
        <Header>
          <Logo img={logoVA} as="h1" to="/" />
        </Header>
        <MenuBar>
          <MenuSection>
            <Menu>
              <MenuItem to="/#buch">
                <FontAwesomeIcon icon={["fal", "book"]} /> Buch
              </MenuItem>
              <MenuItem to="/blog">
                <FontAwesomeIcon icon={["fal", "pencil"]} /> Blog
              </MenuItem>
              <MenuItem to="/fragen">
                <FontAwesomeIcon icon={["fal", "question"]} /> Fragen
              </MenuItem>
              <MenuItem to="/bestellen">
                <FontAwesomeIcon icon={["fal", "shopping-cart"]} /> Bestellen
              </MenuItem>
            </Menu>
          </MenuSection>
        </MenuBar>
        <Main>{children}</Main>
        <Footer
          revision={site.siteMetadata.date}
          browser={browser.name + " " + browser.version}
        >
          <div>
            <Link to="/">© Vera Anders</Link>

            <br />
            <a
              href="http://webseiten-anders.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              © webseiten anders
            </a>
          </div>
          <a
            href="https://www.instagram.com/schreib_leicht/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "72px" }}
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>

          <div>
            <Link to="/impressum/">Impressum</Link>
            <br />
            <Link to="/datenschutz/">Datenschutz</Link>
          </div>

          {/* <span>Revision: {site.siteMetadata.date}</span>
          <span>
            Browser: {}
          </span> */}
        </Footer>
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="psbgm"
          style={{ background: "#465A65" }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
        >
          Mit Nutzung dieser Webseite stimme ich der Verwendung von Cookies zu.
        </CookieConsent>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
