import styled from "styled-components"
import breakpoints from "../../../theme/breakpoints"
import theme from "../../../theme"

const LogoImg = styled.img`
  /* flex-shrink: 0; */
  /* filter: invert(1); */
  display: inline-block;
  width: 250px;
  @media (min-width: ${breakpoints.xs}) {
    width: 300px;
  }
  @media (min-width: ${breakpoints.sm}) {
    width: 400px;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 500px;
  }
  /* https://codepen.io/sosuke/pen/Pjoqqp */
  filter: invert(7%) sepia(9%) saturate(519%) hue-rotate(2deg) brightness(94%)
    contrast(93%);
`

export default LogoImg
