import styled from "styled-components"
import theme from "../../theme"
import lightpaperfibers from "../../img/lightpaperfibers_@2X.png"

const Container = styled.div`
  /* background: ${theme.bgMain || "#fff"}; */
  background-image: url(${lightpaperfibers});
  background-repeat: repeat;
`

export default Container
