import React from "react"
import PropTypes from "prop-types"

import FooterStyled from "./styled/FooterStyled"
import FooterSection from "./styled/FooterSection"
import FooterSmallprint from "./styled/FooterSmallprint"

const Footer = ({ children, revision, browser }) => {
  return (
    <FooterStyled>
      <FooterSection>{children}</FooterSection>
      <FooterSmallprint>
        <span>Revision: {revision}</span>
        <span>Browser: {browser}</span>

        <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>
      </FooterSmallprint>
    </FooterStyled>
  )
}

export default Footer

Footer.propTypes = {
  children: PropTypes.any,
}
