import styled from "styled-components"
import { Link } from "gatsby"
import breakpoints from "../../../theme/breakpoints"

const LinkStyled = styled(Link)`
  background: transparent;
  color: #fff;
  display: table-cell;
  font-size: 0.65rem;
  line-height: 1.5;
  padding-left: 0.5rem;
  text-decoration: none;
  vertical-align: bottom;
  &:hover {
    color: #e9e8e3;
  }
  @media (min-width: ${breakpoints.xs}) {
    font-size: 0.75rem;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding-left: 1rem;
    font-size: 1rem;
  }
`

export default LinkStyled
