import styled from "styled-components"
import breakpoints from "../../../theme/breakpoints"
import theme from "../../../theme"

const LogoStyled = styled.div`
  margin: 0;
  color: #1a1917;
  font-family: ${theme.bodyFontFamily.join(", ")};
  font-weight: normal;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1.1;
  @media (min-width: ${breakpoints.xs}) {
    font-size: 1.4rem;
    line-height: 1.1;
  }
  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
    line-height: 1;
  }
`

export default LogoStyled
