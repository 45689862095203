import styled from "styled-components"
import { space, typography } from "styled-system"
import theme from "../../theme"
import breakpoints from "../../theme/breakpoints"
import bullet from "./squared_plus.svg"

export const H1 = styled.h1`
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights[3]};
  margin-top: 1.2777778rem;
  margin-top: 0;
  margin-bottom: 0;
  ${space}
`
export const H2 = styled.h2`
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights[3]};
  margin-top: 1.2777778rem;
  margin-top: 0;
  margin-bottom: 0;
  ${space}
`

export const H3 = styled.h3`
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights[3]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`

export const H4 = styled.h4`
  font-size: ${theme.fontSizes[4]};
  line-height: ${theme.lineHeights[4]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`
export const H5 = styled.h5`
  font-size: ${theme.fontSizes[5]};
  line-height: ${theme.lineHeights[5]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`
export const P = styled.p`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
  ${typography}
`

export const Ul = styled.ul`
  list-style: none;
  margin: 0rem;
  margin-bottom: 1.2777778rem;
  margin-left: 1.1rem;
  ${space}
`

export const Li = styled.li`
  margin-top: 0rem;
  margin-bottom: 0;
  margin-left: 1rem;
  ${space}
`

export const Li_squared = styled.li`
  margin-top: 0rem;
  margin-bottom: 0;
  margin-left: 0rem;
  ${space}
  &:before {
    margin-left: -1.1rem;
    content: "";
    display: inline-block;
    height: 0.6em;
    width: 0.6em;
    background-image: url(${bullet});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0.5em;
  }
`

export const Ol = styled.ol`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  > li {
    font-weight: bold;
  }
  ${space}
`

export const Pre = styled.pre`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Table = styled.table`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Blockquote = styled.blockquote`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Hr = styled.hr`
  border: 1px solid;
  margin: -1px 0;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Div = styled.div`
  ${space}
`
