import styled from "styled-components"
import theme from "../../../theme"
import breakpoints from "../../../theme/breakpoints"

const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: smaller;
  @media (min-width: ${breakpoints.sm}) {
    font-size: inherit;
  }
  padding: 1rem 0;
  background: ${theme.bgHeader || "#333"};
  color: #1a1917;
  & a {
    color: #1a1917;
  }
  & a:hover {
    color: #4d4b3f;
  }
  height: ${theme.footerHeight || "200px"};
`

export default FooterStyled
