import styled from "styled-components"

const MenuStyled = styled.div`
  font-family: "Istok Web", sans-serif;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  text-transform: uppercase;
`

export default MenuStyled
