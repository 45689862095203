import styled from "styled-components"
import theme from "../../../theme"

const SectionStyled = styled.section`
  display: flex;
  max-width: ${theme.sectionWidth};
  margin: auto;
  padding: 3rem 0.5rem;
  padding: 0 0.5rem;
`

export default SectionStyled
